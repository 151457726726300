.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #df4f4f;
  padding-top: 20px;
}

.profile-picture {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin: 0 0 20px 20px;
  border: 8px solid #f86363;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.sidebar a {
  padding: 15px;
  text-decoration: none;
  font-size: 18px;
  color: white;
  display: block;
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sidebar a:hover {
  background-color: #ddd;
  color: black;
}

.content {
  margin-left: 260px;
  padding: 20px;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 100vh;
}

.name {
  font-size: 80px;
  font-weight: bold;
  color: #df4f4f;
  margin-bottom: 10px;
}

.contact-details {
  color: #453b3b;
  font-size: 20px;
  margin-bottom: 20px;
}

/* Work Experience */
.work-experience {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.designation {
  font-size: 60px;
}
.organization-detail {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
}
.organization {
  font-size: 40px;
}
.workPeriod {
  font-size: 20px;
}
.description {
  font-size: 20px;
}

/* Contact Form */
.contact-form {
  width: 80%;
  padding: 20px;
  text-align: center;
}

.contact-form form {
  width: 80%;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
  width: 80%;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  height: 100px;
}

.submit-btn {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-btn:hover {
  background-color: #555;
}
